import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Layout
import LikeUnlikeButtonUtilisateur from './LikeUnlikeButtonUtilisateur';
import ShareLink from '../../global/ShareLink';
import Note from '../../global/MoyenneNote';

//Global
import GestionTagLists from '../../global/GestionTagListDisplay';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';


//Assets
import flecheDroite from '../../../assets/fleche-petite-droite.png';
import LogoLeekWeSimple from '../../../assets/Logo-LeekWe-simple.webp';

const PublicationListUtilisateur = ({ publications }) => {
    
    const generalConfigSysteme = useConfig();

    const [localPublications, setLocalPublications] = useState([]);

    //Initialisation de la publication en local
    useEffect(() => {
        setLocalPublications(publications);
    }, [publications]);
    

    return (
        <div className="container">
            <ul className="user-list">
                {localPublications.map((publication) => (
                    <li key={publication.uuid} className="user-item">
                        <div className="user-info">
                            <img
                                alt={`${publication.prenom || 'Utilisateur'} - Profil`}
                                className="image-profil"
                                src={publication.lien_photo_profil ? generalConfigSysteme.imageLinkBaseURL + publication.lien_photo_profil : LogoLeekWeSimple}
                            />
                            <div className="user-details">
                                <h2 className="sousTitre2">{publication.prenom || 'N/A'}</h2>
                                <h3 className="sousTitre2Gris">{publication.titre || 'N/A'}</h3>
                            </div>
                        </div>
    
                        {publication.is_confidential !== 1 ? (
                            <>
                                <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                    <img
                                        alt={`Publication de ${publication.prenom || 'Utilisateur'} avec ${publication.titre || 'Organisation'}`}
                                        className="image-publication"
                                        loading="lazy"
                                        src={publication.lien_image ? generalConfigSysteme.imageLinkBaseURL + publication.lien_image : LogoLeekWeSimple}
                                    />
                                </Link>
    
                                <span style={{ display: 'block', height: '20px' }}></span>
    
                                <div className="action-container">
                                    <div className="left-actions">
                                        <LikeUnlikeButtonUtilisateur
                                            publication={publication}
                                            setLocalPublications={setLocalPublications}
                                        />
                                        <ShareLink 
                                            generatedShareLink={`https://www.leekwe.com/publication/${publication.uuid}`} 
                                        />
                                        <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                            <Note moyenne={publication.moyenne_avis_client} />
                                        </Link>
                                    </div>
                                    <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                        <p className='description'>Consulter</p>
                                        <img 
                                            src={flecheDroite} 
                                            alt='Flèche vers publication'
                                            style={{ width: '24px', height: '24px', marginLeft: '5px' }}
                                        />
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                <p className="descriptionConfidentiel">
                                    La photo de cette publication n'est pas disponible. Elle a été définie comme confidentielle.
                                </p>
                            </Link>
                        )}
    
                        <span style={{ display: 'block', height: '15px' }}></span>
    
                        <Link to={`/publication/${publication.uuid}`} className="publication-link">
                            <p className="description">
                                {publication.prenom || 'N/A'} a bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                            </p>
                        </Link>
    
                        <span style={{ display: 'block', height: '10px' }}></span>
    
                        <Link to={`/publication/${publication.uuid}`} className="publication-link">
                            <GestionTagLists tags={publication.tag_prestation || []} />
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
    
};


export default PublicationListUtilisateur;
