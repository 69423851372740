import React, { useState } from 'react';

import Modal from './ConnexionInscriptionFenetre';

//Assets
import CoeurVide from '../../../assets/icone-coeur-vide.png';


const LikeUnlikeButtonVisiteur = ({ publication }) => {
    const [isModalVisible, setModalVisible] = useState(false);

    const handleLikeClick = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <button onClick={handleLikeClick} className="likeButton">
            <img 
                src= {CoeurVide} 
                alt='Unlike'
                style={{ width: '24px', height: '24px'  }}
            />
        </button>
            <Modal isVisible={isModalVisible} onClose={closeModal} />
        </div>
    );
};



export default LikeUnlikeButtonVisiteur;
